import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { PipeConfig } from 'app/core/types/pipes.type';

@Component({
  selector: 'compare-modified-fields-popup',
  templateUrl: './compare-modified-fields-popup.component.html',
  styleUrl: './compare-modified-fields-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareModifiedFieldsPopupComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() originalData: any;
  @Input() updateData: any;
  @Input() dataMapper: any[];

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  data: any;

  ngOnInit(): void {
    const dataArray: any[] = [];
    this.dataMapper.forEach(item => {
      const computedItem = this.computeItem(item);
      if (computedItem?.children.length || computedItem?.originalData !== computedItem?.updateData) {
        dataArray.push(computedItem);
      }
    });
    this.data = dataArray;
  }

  computeItem(item: any, parentKey?: any[]) {
    const computedParentKey = this._buildParentPathKey(parentKey, item.childrenKey); // ??
    const children = this.computeChildren(
      NextValueHelper.defaultValue(item.children, []),
      computedParentKey,
      item.isArray,
      item.pipeList
    );
    let originalData, updateData;

    if (item.path) {
      const computedPathKey = this._buildParentPathKey(parentKey, item.path);
      originalData = NextObjectHelper.getPropertyFromObject(this.originalData, computedPathKey);
      updateData = NextObjectHelper.getPropertyFromObject(this.updateData, computedPathKey);
    }

    if (children.length || originalData !== updateData) {
      if (item.isArray) {
        return {
          titleKey: item.titleKey,
          children: children.flat(),
          originalData,
          updateData,
          isArray: !!item.isArray,
          pipeList: item.pipeList
        };
      }
      return { titleKey: item.titleKey, children, originalData, updateData, isArray: !!item.isArray, pipeList: item.pipeList };
    }

    return null;
  }

  computeChildren(childrenList: any[], parentKey?: any[], isArray?: boolean, pipeList?: PipeConfig): any[] {
    if (isArray) {
      const originalCurrent = NextObjectHelper.getPropertyFromObject(this.originalData, parentKey, []);
      const updatedCurrent = NextObjectHelper.getPropertyFromObject(this.updateData, parentKey, []);
      const computedList = [];
      const length = Math.max(originalCurrent.length, updatedCurrent.length);
      for (let i = 0; i <= length - 1; i++) {
        const parentPlusIndex = this._buildParentPathKey(parentKey, [i]);
        computedList.push(this.computeItemChildren(childrenList, parentPlusIndex));
      }
      return computedList;
    }
    return this.computeItemChildren(childrenList, parentKey);
  }

  computeItemChildren(childrenList: any[], parentKey?: any[]) {
    const computedChildren: any[] = [];
    if (childrenList.length) {
      childrenList.forEach((item: any) => {
        let computedItem = this.computeItem(item, parentKey);
        if (computedItem) {
          if (item.isArray) {
            computedItem = { ...computedItem, children: computedItem.children.flat() };
          }
          computedChildren.push(computedItem);
        }
      });
    }
    return computedChildren;
  }

  private _buildParentPathKey(currentKey?: any[], nextKey?: any[]) {
    if (!nextKey?.length) {
      return currentKey;
    }

    if (currentKey) {
      return [...currentKey, ...nextKey];
    }

    return nextKey;
  }
}
